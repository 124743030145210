type charityInfo = {
    title: string;
    link: string;
};
export const charity0 = {
    title: 'Christchurch City Mission',
    link: 'https://givealittle.co.nz/fundraiser/sequenteers-raising-money-through-cooperation-3',
} as charityInfo;
export const charity1 = {
    title: 'Big Brothers Big Sisters (Youth Support)',
    link: 'https://givealittle.co.nz/fundraiser/sequenteers-raising-money-through-cooperation-big',
} as charityInfo;
export const charity2 = {
    title: 'Epic Sports Project',
    link: 'https://givealittle.co.nz/fundraiser/sequenteers-raising-money-through-cooperation-epic',
} as charityInfo;
export const charity3 = {
    title: 'SPCA',
    link: 'https://givealittle.co.nz/fundraiser/sequenteers-raising-money-through-cooperation',
};
export const daysShown = 30;
export const seequentHomeUrl = 'https://www.seequent.com';

export const parkSeeqDocUrl = 'https://engage.bentley.com/parkseeqapp/?w=aa596';
export const tosUrl =
    'https://engage.bentley.com/parkseeqapp/items/terms-of-use-parkseeq---draftpdf?w=aa596';
